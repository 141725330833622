import React, { ReactNode } from "react";
import {
  AtlassianNavigation,
  // PrimaryButton,
  ProductHome,
  generateTheme,
} from "@atlaskit/atlassian-navigation";

import Avatar from "@atlaskit/avatar";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/theme.css";
import "./App.css";
import { ProtoLogo } from "../../ProtoLogo";
import Proto from "../../assets/img/proto-1.png";

const navigationTheme = generateTheme({
  backgroundColor: "#f7f7fc",
  highlightColor: "#f7f7fc",
});

const Navigation = () => {
  const Home = () => (
    <ProductHome
      // siteTitle="Protoleus"
      icon={() => (
        <Avatar
          size="medium"
          component={() => (
            <img
              src={Proto}
              height={24}
              width={24}
              style={{ borderRadius: 3 }}
            />
          )}
        />
      )}
      logo={() => (
        <Avatar size="medium" component={() => <ProtoLogo size={30} />} />
      )}
    />
  );

  return (
    <div className="sticky-top">
      <AtlassianNavigation
        theme={navigationTheme}
        label="site"
        primaryItems={
          [
            /* left most */
          ]
        }
        renderProductHome={Home}
        // renderNotifications={() => (
        //   <PrimaryButton key="ServicesTab">Services</PrimaryButton>
        // )}
        // renderHelp={() => <PrimaryButton key="MobileTab">Mobile</PrimaryButton>}
        // renderProfile={() => (
        //   /* Right most */
        //   <PrimaryButton key="AboutTab">About</PrimaryButton>
        // )}
      />
    </div>
  );
};

const App = ({ children }: { children: ReactNode }) => (
  <main className="cs-page-wrapper">
    <Navigation />
    {children}
  </main>
);

export default App;
