import React from 'react';

import {
  browserHistory,
  Router,
  Route,
  IndexRoute
} from 'react-router'

import { Landing } from '../Pages/Landing/Landing'
import App from './App'




const GenericNotFound = () => (
  <>
    <h6 className="h1 mb-5 font-weight-400">Page not found</h6>
    <br />
    {/* <button onClick={() => browserHistory.push('/')} type="button" className="btn btn-primary rounded-pill">Return Home?</button> */}
  </>
)


const AppRouter = () => {
  return (
    <Router history={browserHistory}>
      <Route path='/' component={App}>
        <IndexRoute component={Landing} />

        {/* Need to create an auth wrapper */}
        <Route path='services'>
          {/* <Route path='react-native' component={SignUp} /> */}
        </Route>

        <Route path='*' exact={true} component={GenericNotFound} />
      </Route>
    </Router>
  )
}

export default AppRouter;