import React, { useEffect, useRef, useState } from "react";

import Parallax from "parallax-js";

import Layer1 from "../../../assets/img/demo/creative-agency/parallax/layer01.svg";
import Layer2 from "../../../assets/img/demo/creative-agency/parallax/layer02.svg";
import Layer3 from "../../../assets/img/demo/creative-agency/parallax/layer03.svg";
import Layer4 from "../../../assets/img/demo/creative-agency/parallax/layer04.svg";
import Layer5 from "../../../assets/img/demo/creative-agency/parallax/layer05.svg";
import Layer6 from "../../../assets/img/demo/creative-agency/parallax/layer06.svg";
import Layer7 from "../../../assets/img/demo/creative-agency/parallax/layer07.svg";
import Layer8 from "../../../assets/img/demo/creative-agency/parallax/layer08.svg";
import Layer9 from "../../../assets/img/demo/creative-agency/parallax/layer09.svg";
import Layer10 from "../../../assets/img/demo/creative-agency/parallax/layer10.svg";
import Layer11 from "../../../assets/img/demo/creative-agency/parallax/layer11.svg";
import Layer12 from "../../../assets/img/demo/creative-agency/parallax/layer12.svg";
import Layer13 from "../../../assets/img/demo/creative-agency/parallax/layer13.png";
import Layer14 from "../../../assets/img/demo/creative-agency/parallax/layer14.svg";
// import Proto from '../../../assets/img/proto-1.png';
import Collapse from "@kunukn/react-collapse";

const QA = () => {
  const faqInit = {
    // 0: {
    //   title: "How much should I budget for an MVP app?",
    //   sent: [
    //     `We believe that time to market is an important thing for any MVP. That’s why we limit the production timeline of any product to 3 months and $30000 as a budget limit. Within that budget, you can achieve a value bringing product that your users can start paying for.`,
    //   ],
    //   show: true,
    //   id: 0,
    // },
    1: {
      title: "How long will it take to build MVP?",
      sent: [
        `To build a solid product, you need to get it prototyped, designed, developed and tested. To cover all these stages, it will take you about 3 months.`,
        `So, yes, you need 3 months to build MVP. However, it’s hardly possible to claim something like ‘3 months to spend on MVP development,
        and I’ll be done with it’. In fact, you’ll never become ‘done’ - because product building is never a destination but an ongoing process.`,
      ],
      show: false,
      id: 1,
    },
    2: {
      title: "So, why 3 months to build MVP?",
      sent: [
        `Firstly, there’s no need to spend years on MVP development. Time to market matters, so you should validate the idea within the shortest possible time.`,
        `From our experience, the 3-month time frame is perfect to build MVP. Within this time, it’s possible to get a high-quality product that brings real value
        to users and looks convincing enough to turn them into paying customers or be proposed to investors.`,
      ],
      show: false,
      id: 2,
    },
    3: {
      title: "How is MVP different from other prototypes?",
      sent: [
        `Unlike prototypes, MVP is a functional product, not just a set of paper-based sketches. It somehow works (not the same as poor execution, though)
        and already brings real value to end users. It is meant to be your ‘true’ starting point, so you can use it to start tracking purchase intent (how many users
        will turn into customers) or get funded.`,
      ],
      show: false,
      id: 3,
    },
    4: {
      title: "Why build MVP first?",
      sent: [
        "Whether or not you wanna get started with MVP, idea validation must be the top priority. You can pawn your house and get the money needed to build a complete product.",
        `Another scenario would be to take small steps (not giant leaps) and test things out with something fast and simple. 
        In both cases, it might turn out that the idea isn’t worth pursuing - the difference is that in the second case you won’t end up being a homeless entrepreneur`,
      ],
      show: false,
      id: 4,
    },
    5: {
      title: "My MVP is done. What should I do next?",
      sent: [
        `We firmly believe that the MVP development path is like`,
        `“Get started with something very quick and simple → test → improve → repeat”`,

        `You’re likely to know that MVP development is an ongoing process of improving, right? It means that once you’ve got your MVP designed, developed
      and tested, you’re not done - in businesses, it’s hardly possible to ever become ‘done’. So, share your ‘done’ MVP with the market and ask for opinions
      - because the only way to arrive at the ‘Truly Viable Product’ stage is when all the tweaks are feedback-based (not imagination-based).
      `,
      ],
      show: false,
      id: 5,
    },
    6: {
      title: "What is needed from me to get started with MVP development?",
      sent: [
        `No fancy documentation or white papers needed. It’s good if you have such, but to initiate the process a simple interview is enough. We’ll ask the questions about your idea and document it in a proper way.`,
      ],
      show: false,
      id: 6,
    },
    7: {
      title: "Will you sign an NDA?",
      sent: ["Sure thing!"],
      show: false,
      id: 7,
    },
    8: {
      title: "Do you provide marketing services",
      sent: [
        `Our strength areas are UI/UX, development, QA and management. Not marketing. Bringing you ‘average’ results isn’t what we strive for,
so you’d better find true marketing experts for that purpose.`,
      ],
      show: false,
      id: 8,
    },
  } as const;

  type FaqsIds = keyof typeof faqInit;

  const [faqs, updateFaqs] = useState(faqInit);
  const toggleFaqById = (id: FaqsIds) => {
    updateFaqs((state) => {
      const faqById = state[id];
      return {
        ...state,
        [`${id}`]: {
          ...faqById,
          show: !faqById.show,
        },
      };
    });
  };

  const getShowById = (id: FaqsIds) => faqs[id].show;
  const FAQList = Object.values(faqs).map((faq) => {
    return (
      <div key={`faq-${faq.id}`} className="card border-0 box-shadow">
        <div className="card-header">
          <h3 className="accordion-heading">
            <a
              role="button"
              onClick={() => toggleFaqById(faq.id)}
              className={getShowById(faq.id) ? "" : "collapsed"}
            >
              {faq.title}
              <span className="accordion-indicator"></span>
            </a>
          </h3>
        </div>
        <Collapse isOpen={getShowById(faq.id)}>
          <div className={getShowById(faq.id) ? "" : "collapse"}>
            <div className="card-body font-size-sm">
              {/* @ts-ignore */}
              {faq.sent.map((sentence, i) => (
                <p key={`sent-${faq.id}-${i}`} className="mb-0">
                  {sentence}
                  <br />
                  <br />
                </p>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    );
  });

  return (
    <section
      className="position-relative bg-secondary py-5 py-md-6 py-lg-7"
      style={{ marginTop: "-200px" }}
    >
      <div style={{ height: "200px" }}></div>
      <div className="cs-shape cs-shape-top cs-shape-curve bg-body">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
          <path
            fill="currentColor"
            d="M3000,185.4V0H0v185.4C496.4,69.8,996.4,12,1500,12S2503.6,69.8,3000,185.4z"
          ></path>
        </svg>
      </div>
      <div className="container mt-n4 py-3 py-md-2">
        <h2 className="text-center mb-5">Questions &amp; Answers</h2>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9">
            <div className="accordion accordion-alt" id="faq">
              {FAQList}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Landing = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
      hoverOnly: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <>
      <section className="position-relative bg-secondary pt-5 pt-lg-7 pb-7 overflow-hidden">
        <div className="cs-shape cs-shape-bottom cs-shape-curve bg-body">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
            <path
              fill="currentColor"
              d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z"
            ></path>
          </svg>
        </div>
        {/* <!-- Content--> */}
        <div className="container pt-3 pb-4 pt-lg-0">
          <div className="row align-items-center">
            <div className="col-lg-5 mt-lg-n7 pb-5 mb-sm-3 mb-lg-0 pb-lg-0 text-center text-lg-left">
              <h1 className="display-4">Creativity beyond imagination</h1>
              <p className="pb-2">
                Let&apos;s help bring your wildest ideas to life.
              </p>
              {/* Watch video demo */}
              {/* <a className="cs-video-btn cs-video-btn-primary mr-3" href="https://vimeo.com/93641234"
                data-sub-html="&lt;h6 class=&quot;font-size-sm text-light&quot;&gt;Video caption&lt;/h6&gt;"></a>
              <span className="font-size-sm text-muted">Watch who, how and where</span> */}
            </div>
            <div className="col-lg-7">
              <div
                className="cs-parallax mx-auto"
                ref={sceneEl}
                style={{ maxWidth: "705px" }}
              >
                <div
                  className="cs-parallax-layer position-relative"
                  data-depth="0.1"
                >
                  <img src={Layer1} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.35">
                  <img src={Layer2} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.2">
                  <img src={Layer3} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.5">
                  <img src={Layer4} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.6">
                  <img src={Layer5} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.4">
                  <img src={Layer6} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.25">
                  <img src={Layer7} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.35">
                  <img src={Layer8} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.6">
                  <img src={Layer9} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.45">
                  <img src={Layer10} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.7">
                  <img src={Layer11} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.3">
                  <img src={Layer12} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.15">
                  <img src={Layer13} alt="Layer" />
                </div>
                <div className="cs-parallax-layer" data-depth="0.9">
                  <img src={Layer14} alt="Layer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About--> */}
      <section
        className="container bg-overlay-content"
        style={{ marginTop: "-175px" }}
      >
        <div className="bg-light rounded-lg box-shadow py-5 py-md-6 px-4 px-md-0">
          <div className="row align-items-center py-3 py-md-0">
            <div className="col-xl-6 col-lg-5 col-md-4 text-center">
              <h2 className="pb-3 pb-md-0">
                <span className="d-block text-body opacity-25">
                  {/* Protoleus */}
                </span>
                About Us
              </h2>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-7 text-center text-md-left">
              <p>
                A digital consulting company of thinkers and tinkers looking to
                help business enthusiasts from all over the world with UI/UX,
                web, and mobile app development. Our goal is to help startups
                not only validate their business/product hypotheses but to also
                help them rapidly build, test, and release v1 of their product.
              </p>
              {/* <p className="mb-0">Ornare aenean euismod elementum nisi quis. Et ultrices neque ornare aenean euismod elementum
              nisi.</p> */}
            </div>
          </div>
        </div>
      </section>
      {/* Q & A */}
      <QA />
    </>
  );
};
